<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Utilisateurs Autopartage",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      userType: ["CONDUCTEUR", "PARTICULIER", "TAXIMAN", "SOCIETE"],
      userTypeList: [
        { 
        id:  "CONDUCTEUR",
        value:"MOTO"
      
      },
        {
         id: "PARTICULIER",
       value: "VOITURE"
      },
        {
          id:"TAXIMAN", value:"TAXI"
        },
        {id:"SOCIETE",
        
        value:"BUS"
      },
        {id:"PASSAGER",
        value:"PASSAGER"
      },
        
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "ville", sortable: true, label: "Ville" },
        { key: "pays", sortable: true, label: "Pays" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "action", sortable: true, label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      totalValue: "0",
      userToUse: 0,
      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
        indicatifTel:"",
        indicatifContactUrg:""
      },
      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      userChargeLoader: false,
      newDataUserRmobility: [],
      newStart: false,
      startValue: 1,
      endValue: 500,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length + this.dataUserD.length;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/particuliers",
        undefined,
        false
      );
      if (data && data.data) {
        this.totalValue = data.data.total
        this.totalRows = data.data.total
        const formattedTable = data.data.users.map((user) => {
          var username = `${
            user.personne.lastname == "" ? "Nom" : user.personne.lastname
          } ${
            user.personne.firstname == "" ? "Prénom" : user.personne.firstname
          }`;

          return {
            id: user.id,
            username: username,
            ville: user.personne.adresse,
            pays: user.personne.pays,
            tel:
              user.personne.tel && user.personne.tel != ""
                ? user.personne.indicatifTel + user.personne.tel
                : "",
            email: user.personne.email,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
          };
        });
        this.dataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;

      }
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/rmo/particuliers?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.users.map((user) => {
          var username = `${
            user.personne.lastname == "" ? "Nom" : user.personne.lastname
          } ${
            user.personne.firstname == "" ? "Prénom" : user.personne.firstname
          }`;

          return {
            id: user.id,
            username: username,
            ville: user.personne.adresse,
            pays: user.personne.pays,
            tel:
              user.personne.tel && user.personne.tel != ""
                ? user.personne.indicatifTel + user.personne.tel
                : "",
            email: user.personne.email,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
          };
        });
        this.newDataUserRmobility = formattedTable;
        this.rowsdataUser = formattedTable.length;

        this.totalRows = formattedTable.length;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;

      }
    },

    async userSearch() {
      console.log(this.filter);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/rmo/particuliers/recherche?mots="+this.filter,


        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          var username = `${
            user.personne.lastname == "" ? "Nom" : user.personne.lastname
          } ${
            user.personne.firstname == "" ? "Prénom" : user.personne.firstname
          }`;

          return {
            id: user.id,
            username: username,
            ville: user.personne.adresse,
            pays: user.personne.pays,
            tel:
              user.personne.tel && user.personne.tel != ""
                ? user.personne.indicatifTel + user.personne.tel
                : "",
            email: user.personne.email,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
          };
        });
        this.newDataUserRmobility = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
        this.userChargeLoader = false;
       
      }
    },

    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;

      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.mediaSelect.value,
          url: this.url_link,
        },
        false
      );

      if (data && data.data) {
        this.showSucess = true;
        this.init();
      } else {
        this.showEchec = true;
        this.init();
      }
    },

    async ModifSubmit() {
      this.showEdit = false;
      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          indicatifTel:this.infoUser.indicatifTel,
          email: this.infoUser.email,
          adresse: this.infoUser.adresse,
          ville: this.infoUser.adresse,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeLocal.id,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.personneUrgence,
          contactUrgence: this.infoUser.contactUrgence,
          indicatifContactTel: this.infoUser.indicatifContactUrg,
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.showSucessEdit = true;
     

        this.$toast.success("Modification effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      } else {
        this.showEchecEdit = true;
        this.$snotify.console.error();
        "La modification a échouée.", "Oups";
        this.init();
      }
    },

    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },

    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;
      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.userType;
        this.userTypeLocal = dataDetail.data.userType;
      }
    },

    desactiverUser(row) {
      this.userToUse = row.id;

      this.showDelete = true;
    },

    async desactiveUser() {
      this.showDelete = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {
        this.position();
        this.init();
      }
    },

    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
    },

    setDepartContry: function (place) {
      //console.log('place:', place)
      if (place) {
        //console.log("place:", place);
        /*console.log('place.formatted_address:', place.formatted_address)*/
        this.infoUser.ville = place.formatted_address;
      }
    },

    async activeUser(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    deleteUser(row) {
      this.userToUse = row.id;
      this.deleteModal = true;
    },

    async deleteU() {
      this.deleteModal = false;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    goToDetail(row) {
      console.log("row:", row);
      router.push(`/agence/userlocation_info/${row.id}`);
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    refuseDialog(row){
       this.userToUse = row.id;
       
        this.showDelete=true;
      
        
  
      },


      async refuser() {
      
      const data = await apiRequest(
       "POST",
        "admin/rmo/particulier/action",
        {
          idVehicule: this.userToUse,
          actif: false,
        },
        false
      );
     
      if (data && data.data) {
           this.showDelete=false;
 

       this.$toast.success("Utilisateur désactivé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      }
    },





  },
};
</script>
    
    <template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="containerDot" v-if="dataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
          

            <div>
              <h4 class="card-title mt-4">
                 Total d'utilisateurs : {{ totalRows }}
              </h4>
            </div>
            <transition mode="out-in">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <li>
                    <span class="interval"> {{ startValue }}</span> -
                    <span class="interval">{{ endValue }}</span> sur
                    {{ totalValue }}
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <button
                      class="myBouton"
                      @click="firstVal()"
                      :disabled="startValue == 1"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Prem"
                    >
                      <ion-icon name="play-skip-back-outline"></ion-icon>
                    </button>
                    <button
                      type="button"
                      class="myBouton"
                      @click="decrement()"
                      :disabled="endValue == 500"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Préc"
                    >
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="increment()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Suiv"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="incrementToLast()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Dern"
                      :disabled="endValue >= totalValue"
                    >
                      <ion-icon name="play-skip-forward-outline"></ion-icon>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des utilisateurs : {{ rowsdataUser }}
                  </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataUserRmobility.length!=0?newDataUserRmobility:dataUser"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="
                          py-2
                          d-flex
                          justify-content-center
                          badge
                          font-size-12
                        "
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click=" refuseDialog(row.item)"
                          title="Refuser"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                      <a
                        href="javascript:void(0);"
                        class="text-warning mr-1"
                        @click="
                          messageShow(row.item, {
                            name: 'Notification',
                            value: 0,
                          })
                        "
                        v-b-tooltip.hover
                        title="Notification"
                      >
                        <i class="fas fa-bell"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="
                          messageShow(row.item, { name: 'Mail', value: 1 })
                        "
                        v-b-tooltip.hover
                        title="Mail"
                      >
                        <i class="fas fa-envelope"></i>
                      </a>
                    </template>

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                    
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          :aria-controls="trajet"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button
                              class="myBouton"
                              @click="firstVal()"
                              :disabled="startValue == 1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Prem"
                            >
                              <ion-icon
                                name="play-skip-back-outline"
                              ></ion-icon>
                            </button>
                            <button
                              type="button"
                              class="myBouton"
                              @click="decrement()"
                              :disabled="endValue == 500"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Préc"
                            >
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="increment()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Suiv"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="chevron-forward-outline"
                              ></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="incrementToLast()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Dern"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="play-skip-forward-outline"
                              ></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
              </b-tab>
              <!--   <b-tab title-link-class="p-3">
                    <template v-slot:title>
                      <a class="font-weight-bold active"
                        >Comptes désactivés : {{ rowsdataUserD }}</a
                      >
                    </template>
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-3">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Afficher&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entrées
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6"></div>
            
                      <div class="col-sm-12 col-md-3">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-right"
                        >
                          <label class="d-inline-flex align-items-center">
                            Rechercher:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
        
                    </div>
                    <div class="table-responsive">
                      <b-table
                        style="cursor: pointer"
                        class="table-centered"
                        :items="dataUserD"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        @row-clicked="goToDetail"
                      >
                        <template v-slot:cell(badge)="row">
                          <div
                            class="d-flex justify-content-center"
                            :class="{
                              'fa fa-star': `${row.value}` === 'false',
                              'fa fa-star badge-soft-warning':
                                `${row.value}` === 'true',
                            }"
                          ></div>
                        </template>
                        <template v-slot:cell(statut)="row">
                          <div
                            class="badge font-size-12"
                            :class="{
                              'badge-soft-danger': `${row.value}` === 'Suspendu',
                              'badge-soft-success': `${row.value}` === 'Actif',
                            }"
                          >
                            {{ row.value }}
                          </div>
                        </template>
                        <template v-slot:cell(action)="row">
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            v-b-tooltip.hover
                            @click="activeUser(row.item)"
                            title="Activer"
                          >
                            <i class="mdi mdi-check font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-danger"
                            v-b-tooltip.hover
                            @click="deleteUser(row.item)"
                            title="Supprimer"
                            v-if="row.item.trajet == 0"
                          >
                            <i class="fas fa-trash"></i>
                          </a>
                        </template>
                        <template v-slot:cell(tel)="row" style="text-align: center">
                          <a
                            :href="'https://wa.me/' + row.value"
                            class="text-success"
                            v-b-tooltip.hover
                            title="Envoyer un message whatsapp"
                            target="_blank"
                          >
                            {{ row.value }}
                          </a>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-right
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                      
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-tab> -->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment désactivé cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="refuser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="deleteModal"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="deleteModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteU"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="ModifSubmit"
      >
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="lastname">Nom</label>
            <input
              type="text"
              class="form-control py-4"
              id="lastname"
              v-model="infoUser.lastname"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Prénom</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname"
              v-model="infoUser.firstname"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Profession</label>
            <input
              type="text"
              class="form-control py-4"
              id="profession"
              v-model="infoUser.profession"
            />
          </div>
          <div class="col-md-4 mb-12">
            <label for="firstname">Date de naissance</label>
            <input
              id=""
              v-model="infoUser.birthday"
              type="text"
              class="form-control"
              placeholder=""
            />
          </div>
         
          <div class="col-md-4 mb-3">
            <label for="lastname">Pays</label>
            <input
              type="text"
              class="form-control py-4"
              v-model="infoUser.pays"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Ville de résidence</label>
            <gmap-autocomplete
                               :value="infoUser.ville"
                                type="text"
                                class="form-control"
                                id="depart"
                                placeholder="Ville de départ"
                                @place_changed="setDepartContry"
                                :select-first-on-enter="true"
                                style="height:50px"
                              >
                              </gmap-autocomplete>
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Téléphone</label>
            <div class="row">
              <input
              type="text"
              class="form-control ml-2 mr-2"
              id="telephone"
              v-model="infoUser.indicatifTel"
              style="width: 30% ; height: 50px;"
            />
            <input
              type="text"
              class="form-control "
              id="lastname5"
              v-model="infoUser.telephone"
              style="width: 60% ; height: 50px;"
            />
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Email</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname7"
              v-model="infoUser.email"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Personne à contacter</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname6"
              v-model="infoUser.personneUrgence"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Contact de la personne à contacter</label>
          
            <div class="row">
              <input
              type="text"
              class="form-control ml-2 mr-2"
              id="telephone"
              v-model="infoUser.indicatifTel"
              style="width: 30% ; height: 50px;"
            />
            <input
              type="text"
              class="form-control "
              id="lastname5"
              v-model="infoUser.contactUrgence"
              style="width: 60% ; height: 50px;"
            />
            </div>
          </div>
          <div class="col-md-4 mb-3 maDiv">
            <label class="control-label">Type d'utilisateur</label>
            <multiselect
              v-model="userTypeLocal"
              :options="userTypeList"
              label="value"
            track-by="id"
            ></multiselect>
          </div>
        </div>
        <button class="btn btn-success float-right" type="submit">
          Modifier
        </button>
      </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre message"
          ></ckeditor>
          <!--   
              <textarea
                v-model="messageContent.message"
                placeholder="Votre message"
                class="form-control"
                id=""
                cols="30"
                rows="10"
              ></textarea>-->
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
<style>



.maDiv
  .multiselect__tags {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
 
    height: 30px!important;
    min-height: 30px!important;
    text-align: left;
    color: #35495e;

}
   thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 }
 
 .nav-tabs .nav-link a{
  color: #fff ;
} 

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #3AAA35;
}

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}

.pac-container {
    z-index: 10000 !important;
}


ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
    z-index: 10000 !important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -18%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.inputCol {
  position: relative;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.multiselect__tags{
  min-height: 50px!important;
  height: 50px;
}
</style>
    